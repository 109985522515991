/* eslint-disable camelcase */
export enum UserViewType {
  ADMIN = 1,
  ME = 2
}

export enum RoleInitials {
  Buyer = 'B1I',
  Buyer2 = 'B2I',
  Buyer3 = 'B3I',
  Seller = 'S1I',
  Seller2 = 'S2I',
  Seller3 = 'S3I',
  SellerBroker = 'SBI',
  BuyerBroker = 'BBI',
  BuyerAttorney = 'BAI',
  SellerAttorney = 'SAI',
  ManagingBroker = 'MB'
}

export enum SortDashboardKeys {
  Address = 'address1',
  PropertyType = 'property_type',
  PublishDate = 'publish_date',
  OfferDate = 'offer_date',
  EffectiveDate = 'effective_date',
  ClosingDate = 'closing_date',
  CurrentPrice = 'current_price',
  ExpirationDate = 'expiration_date',
  OffersCount = 'offersCount',
  EarnestMoney = 'earnest_money',
  ClosingCostCredit = 'closing_cost_credits',
  Commisions = 'commissions',
  Expenses = 'expenses',
  ContractPrice = 'contract_price',
  PostClosingEscrows = 'post_closing_escrows',
  Financials = 'financials',
  OfferPrice = 'offer_price',
  Listed = 'date'
}
